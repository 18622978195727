const supportedLanguages = [
    {
        "id": 38,
        "name": "English (USA)",
        "slug": "en",
        "order": 0,
    },
    {
        "id": 106,
        "name": "English (UK)",
        "slug": "en-gb",
        "order": 1,


    },
    {
        "id": 109,
        "name": "English (Australian)",
        "slug": "en-au",
        "order": 2,


    },
    {
        "id": 108,
        "name": "English (Canadian)",
        "slug": "en-ca",
        "order": 3,


    },
    {
        "id": 202,
        "name": "English (South Africa)",
        "slug": "en-ZA",
        "order": 3,


    },
    {
        "id": 39,
        "name": "Español",
        "slug": "es",
        "order": 4,


    },
    {
        "id": 114,
        "name": "Español (Mexicano)",
        "slug": "es-mx",
        "order": 5,


    },
    {
        "id": 40,
        "name": "Deutsch",
        "slug": "de",
        "order": 6,


    },
    {
        "id": 41,
        "name": "عربى",
        "slug": "ar",
        "order": 7,


    },
    {
        "id": 43,
        "name": "Português",
        "slug": "pt",
        "order": 8,


    },
    {
        "id": 92,
        "name": "Português (Brazil)",
        "slug": "pt-br",
        "order": 9,


    },
    {
        "id": 44,
        "name": "日本語",
        "slug": "ja",
        "order": 10,


    },
    {
        "id": 46,
        "name": "Français",
        "slug": "fr",
        "order": 11,


    },
    {
        "id": 47,
        "name": "Italiano",
        "slug": "it",
        "order": 12,


    },
    {
        "id": 48,
        "name": "Polski",
        "slug": "pl",
        "order": 13,


    },
    {
        "id": 49,
        "name": "Pусский",
        "slug": "ru",
        "order": 14,


    },
    {
        "id": 57,
        "name": "中文",
        "slug": "zh",
        "order": 15,


    },
    {
        "id": 93,
        "name": "漢語 (Traditional)",
        "slug": "zh-tw",
        "order": 16,


    },
    {
        "id": 54,
        "name": "עִברִית",
        "slug": "he",
        "order": 17,


    },
    {
        "id": 45,
        "name": "Magyar",
        "slug": "hu",
        "order": 18,


    },
    {
        "id": 42,
        "name": "Slovenčina",
        "slug": "sk",
        "order": 19,


    },
    {
        "id": 50,
        "name": "Suomenkieli",
        "slug": "fi",
        "order": 20,


    },
    {
        "id": 51,
        "name": "Türkçe",
        "slug": "tr",
        "order": 21,


    },
    {
        "id": 52,
        "name": "Ελληνικά",
        "slug": "el",
        "order": 22,


    },
    {
        "id": 53,
        "name": "Latinus",
        "slug": "la",
        "order": 23,


    },
    {
        "id": 55,
        "name": "български",
        "slug": "bg",
        "order": 24,


    },
    {
        "id": 56,
        "name": "Català",
        "slug": "ca",
        "order": 25,


    },
    {
        "id": 58,
        "name": "Čeština",
        "slug": "cs",
        "order": 26,


    },
    {
        "id": 59,
        "name": "Dansk",
        "slug": "da",
        "order": 27,


    },
    {
        "id": 60,
        "name": "Nederlands",
        "slug": "nl",
        "order": 28,


    },
    {
        "id": 61,
        "name": "हिंदी",
        "slug": "hi",
        "order": 29,


    },
    {
        "id": 62,
        "name": "Bahasa Indonesia",
        "slug": "id",
        "order": 30,


    },
    {
        "id": 63,
        "name": "한국인",
        "slug": "ko",
        "order": 31,


    },
    {
        "id": 64,
        "name": "Lietuvių",
        "slug": "lt",
        "order": 32,


    },
    {
        "id": 65,
        "name": "Bahasa Melayu",
        "slug": "ms",
        "order": 33,


    },
    {
        "id": 66,
        "name": "Norsk",
        "slug": "no",
        "order": 34,


    },
    {
        "id": 67,
        "name": "Română",
        "slug": "ro",
        "order": 35,


    },
    {
        "id": 68,
        "name": "Slovenščina",
        "slug": "sl",
        "order": 36,


    },
    {
        "id": 69,
        "name": "Svenska",
        "slug": "sv",
        "order": 37,


    },
    {
        "id": 70,
        "name": "แบบไทย",
        "slug": "th",
        "order": 38,


    },
    {
        "id": 71,
        "name": "Українська",
        "slug": "uk",
        "order": 39,


    },
    {
        "id": 72,
        "name": "Việt Nam",
        "slug": "vi",
        "order": 40,


    },
    {
        "id": 73,
        "name": "বাঙালি",
        "slug": "bn",
        "order": 41,


    },
    {
        "id": 74,
        "name": "සිංහල",
        "slug": "si",
        "order": 42,


    },
    {
        "id": 75,
        "name": "አማርኛ",
        "slug": "am",
        "order": 43,


    },
    {
        "id": 76,
        "name": "Hmoob",
        "slug": "hmn",
        "order": 44,


    },
    {
        "id": 77,
        "name": "မြန်မာ",
        "slug": "my",
        "order": 45,


    },
    {
        "id": 84,
        "name": "Eesti keel",
        "slug": "et",
        "order": 46,


    },
    {
        "id": 85,
        "name": "Latviešu",
        "slug": "lv",
        "order": 47,


    },
    {
        "id": 86,
        "name": "Cрпски",
        "slug": "sr",
        "order": 48,


    },
    {
        "id": 94,
        "name": "Hrvatski",
        "slug": "hr",
        "order": 49,


    },
    {
        "id": 95,
        "name": "ქართული",
        "slug": "ka",
        "order": 50,


    },
    {
        "id": 96,
        "name": "ʻŌlelo Hawaiʻi",
        "slug": "haw",
        "order": 51,


    },
    {
        "id": 97,
        "name": "Cymraeg",
        "slug": "cy",
        "order": 52,


    },
    {
        "id": 99,
        "name": "Cebuano",
        "slug": "ceb",
        "order": 53,


    },
    {
        "id": 98,
        "name": "Samoa",
        "slug": "sm",
        "order": 54,


    },
    {
        "id": 100,
        "name": "Kreyòl ayisyen",
        "slug": "ht",
        "order": 55,


    },
    {
        "id": 101,
        "name": "Føroyskt",
        "slug": "fo",
        "order": 56,


    },
    {
        "id": 102,
        "name": "Crnogorski",
        "slug": "cnr",
        "order": 57,


    },
    {
        "id": 104,
        "name": "Azerbaijani",
        "slug": "az",
        "order": 58,


    },
    {
        "id": 105,
        "name": "Euskara",
        "slug": "eu",
        "order": 59,


    },
    {
        "id": 110,
        "name": "Tagalog",
        "slug": "fil",
        "order": 60,


    },
    {
        "id": 111,
        "name": "Galego",
        "slug": "gl",
        "order": 61,


    },
    {
        "id": 112,
        "name": "Norsk Bokmål",
        "slug": "nb",
        "order": 62,


    },
    {
        "id": 107,
        "name": "فارسی",
        "slug": "fa",
        "order": 63,


    },
    {
        "id": 113,
        "name": "ਪੰਜਾਬੀ",
        "slug": "pa",
        "order": 64,


    },
    {
        "id": 116,
        "name": "Shqiptare",
        "slug": "sq",
        "order": 65,


    },
    {
        "id": 117,
        "name": "Hայերեն",
        "slug": "hy",
        "order": 67,


    },
    {
        "id": 118,
        "name": "অসমীয়া",
        "slug": "as",
        "order": 68,


    },
    {
        "id": 119,
        "name": "Aymara",
        "slug": "ay",
        "order": 69,


    },
    {
        "id": 120,
        "name": "Bamanankan",
        "slug": "bm",
        "order": 70,


    },
    {
        "id": 121,
        "name": "беларускі",
        "slug": "be",
        "order": 71,


    },
    {
        "id": 122,
        "name": "Bosanski",
        "slug": "bs",
        "order": 72,


    },
    {
        "id": 123,
        "name": "Corsu",
        "slug": "co",
        "order": 73,


    },
    {
        "id": 124,
        "name": "ދިވެހި",
        "slug": "dv",
        "order": 74,


    },
    {
        "id": 125,
        "name": "Esperanto",
        "slug": "eo",
        "order": 75,


    },
    {
        "id": 126,
        "name": "Eʋegbe",
        "slug": "ee",
        "order": 76,


    },
    {
        "id": 127,
        "name": "Frisian",
        "slug": "fy",
        "order": 77,


    },
    {
        "id": 128,
        "name": "Guarani",
        "slug": "gn",
        "order": 78,


    },
    {
        "id": 129,
        "name": "ગુજરાતી",
        "slug": "gu",
        "order": 79,


    },
    {
        "id": 130,
        "name": "Hausa",
        "slug": "ha",
        "order": 80,


    },
    {
        "id": 131,
        "name": "íslenskur",
        "slug": "is",
        "order": 81,


    },
    {
        "id": 132,
        "name": "Igbo",
        "slug": "ig",
        "order": 82,


    },
    {
        "id": 133,
        "name": "Gaeilge",
        "slug": "ga",
        "order": 83,


    },
    {
        "id": 134,
        "name": "Basa Jawa",
        "slug": "jv",
        "order": 84,


    },
    {
        "id": 135,
        "name": "ಕನ್ನಡ",
        "slug": "kn",
        "order": 85,


    },
    {
        "id": 136,
        "name": "қазақ",
        "slug": "kk",
        "order": 86,


    },
    {
        "id": 137,
        "name": "ខ្មែរ",
        "slug": "km",
        "order": 87,


    },
    {
        "id": 138,
        "name": "Kinyarwanda",
        "slug": "rw",
        "order": 88,


    },
    {
        "id": 139,
        "name": "Kurdî",
        "slug": "ku",
        "order": 89,


    },
    {
        "id": 140,
        "name": "Кыргызча",
        "slug": "ky",
        "order": 90,


    },
    {
        "id": 141,
        "name": "ພາສາລາວ",
        "slug": "lo",
        "order": 91,


    },
    {
        "id": 142,
        "name": "Lingala",
        "slug": "ln",
        "order": 92,


    },
    {
        "id": 143,
        "name": "Luganda",
        "slug": "lg",
        "order": 93,


    },
    {
        "id": 144,
        "name": "lëtzebuergesch",
        "slug": "lb",
        "order": 94,


    },
    {
        "id": 145,
        "name": "македонски",
        "slug": "mk",
        "order": 95,


    },
    {
        "id": 146,
        "name": "Malagasy",
        "slug": "mg",
        "order": 96,


    },
    {
        "id": 147,
        "name": "മലയാളം",
        "slug": "ml",
        "order": 97,


    },
    {
        "id": 148,
        "name": "Malti",
        "slug": "mt",
        "order": 98,


    },
    {
        "id": 149,
        "name": "Maori",
        "slug": "mi",
        "order": 99,


    },
    {
        "id": 150,
        "name": "मराठी",
        "slug": "mr",
        "order": 100,


    },
    {
        "id": 151,
        "name": "Монгол",
        "slug": "mn",
        "order": 101,


    },
    {
        "id": 152,
        "name": "नेपाली",
        "slug": "ne",
        "order": 102,


    },
    {
        "id": 153,
        "name": "Sea",
        "slug": "ny",
        "order": 103,


    },
    {
        "id": 154,
        "name": "ଓଡିଆ",
        "slug": "or",
        "order": 104,


    },
    {
        "id": 155,
        "name": "Afaan Oromoo",
        "slug": "om",
        "order": 105,


    },
    {
        "id": 156,
        "name": "پښتو",
        "slug": "ps",
        "order": 106,


    },
    {
        "id": 157,
        "name": "Runasimi",
        "slug": "qu",
        "order": 107,


    },
    {
        "id": 158,
        "name": "संस्कृत",
        "slug": "sa",
        "order": 108,


    },
    {
        "id": 159,
        "name": "Gàidhlig na h-Alba",
        "slug": "gd",
        "order": 109,


    },
    {
        "id": 160,
        "name": "Sesotho",
        "slug": "st",
        "order": 110,


    },
    {
        "id": 161,
        "name": "Shona",
        "slug": "sn",
        "order": 111,


    },
    {
        "id": 162,
        "name": "سنڌي",
        "slug": "sd",
        "order": 112,


    },
    {
        "id": 163,
        "name": "Soomaali",
        "slug": "so",
        "order": 113,


    },
    {
        "id": 164,
        "name": "Basa Sunda",
        "slug": "su",
        "order": 114,


    },
    {
        "id": 165,
        "name": "Kiswahili",
        "slug": "sw",
        "order": 115,


    },
    {
        "id": 166,
        "name": "тоҷикӣ",
        "slug": "tg",
        "order": 116,


    },
    {
        "id": 167,
        "name": "தமிழ்",
        "slug": "ta",
        "order": 117,


    },
    {
        "id": 168,
        "name": "Татар",
        "slug": "tt",
        "order": 118,


    },
    {
        "id": 169,
        "name": "తెలుగు",
        "slug": "te",
        "order": 119,


    },
    {
        "id": 170,
        "name": "ትግሪኛ",
        "slug": "ti",
        "order": 120,


    },
    {
        "id": 171,
        "name": "Tsonga",
        "slug": "ts",
        "order": 121,


    },
    {
        "id": 172,
        "name": "Türkmenler",
        "slug": "tk",
        "order": 122,


    },
    {
        "id": 173,
        "name": "Ride",
        "slug": "ak",
        "order": 123,


    },
    {
        "id": 174,
        "name": "اردو",
        "slug": "ur",
        "order": 124,


    },
    {
        "id": 175,
        "name": "ئۇيغۇر",
        "slug": "ug",
        "order": 125,


    },
    {
        "id": 176,
        "name": "o'zbek",
        "slug": "uz",
        "order": 126,


    },
    {
        "id": 177,
        "name": "isiXhosa",
        "slug": "xh",
        "order": 127,


    },
    {
        "id": 178,
        "name": "יידיש",
        "slug": "yi",
        "order": 128,


    },
    {
        "id": 179,
        "name": "Yoruba",
        "slug": "yo",
        "order": 129,


    },
    {
        "id": 180,
        "name": "Zulu",
        "slug": "zu",
        "order": 130,


    },
    {
        "id": 181,
        "name": "भोजपुरी",
        "slug": "bho",
        "order": 131,


    },
    {
        "id": 182,
        "name": "डोगरी",
        "slug": "doi",
        "order": 132,


    },
    {
        "id": 183,
        "name": "कोंकणी",
        "slug": "gom",
        "order": 133,


    },
    {
        "id": 184,
        "name": "Kurdî",
        "slug": "ckb",
        "order": 134,


    },
    {
        "id": 185,
        "name": "Krio",
        "slug": "kri",
        "order": 135,


    },
    {
        "id": 186,
        "name": "मैथिली",
        "slug": "mai",
        "order": 136,


    },
    {
        "id": 187,
        "name": "ꯃꯩꯇꯩꯂꯣꯟ",
        "slug": "mni",
        "order": 137,


    },
    {
        "id": 188,
        "name": "Mizo tawng",
        "slug": "lus",
        "order": 138,


    },
    {
        "id": 189,
        "name": "Sepedi",
        "slug": "nso",
        "order": 139,


    },
    {
        "id": 190,
        "name": "Ilocano",
        "slug": "ilo",
        "order": 140,


    }
]
export default supportedLanguages;